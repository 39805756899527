/**
 *
 * SightingFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { documentAction } from 'domain/typeConstants/action';
import { SIGHTING_DOCUMENTS_DX_GRID, SIGHTING_DOCUMENTS_FORM, SIGHTING_DOCUMENTS_FORM_DIALOG } from './constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants'
import { ON_ARCHIVED } from 'domain/Data/DocumentState/constants';
import { DOCUMENTS } from 'domain/Data/constants';


class SightingFormDialog extends React.Component {

  handleRunButtonClick = () => this.props.onRun(SIGHTING_DOCUMENTS_FORM_DIALOG, SIGHTING_DOCUMENTS_FORM)

  handleAgreeButtonClick = () => this.props.onAgree(SIGHTING_DOCUMENTS_FORM_DIALOG, SIGHTING_DOCUMENTS_FORM)

  handleDisagreeButtonClick = () => this.props.onDisagree(SIGHTING_DOCUMENTS_FORM_DIALOG, SIGHTING_DOCUMENTS_FORM)

  renderCustomButtons() {
    const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
    const { currentRecord, selection, agreementStep } = this.props;
    const state = currentRecord ? currentRecord.state?.id : selection && selection[0] ? selection[0]?.state?.id : null
    const sightingEmployees = currentRecord ? currentRecord?.agreementStep?.sightingEmployeesText : agreementStep
    const isSightingEmployee = sightingEmployees && sightingEmployees.split(',').map(i => i.trim()).includes(userName);
    const isArchivedState = state === ON_ARCHIVED

    return [
      {
        name: documentAction.RUN,
        label: 'Отправить по маршруту',
        onClick: this.handleRunButtonClick,
        disabled: isArchivedState,
      },
      {
        name: documentAction.AGREE,
        label: 'Согласовать',
        onClick: this.handleAgreeButtonClick,
        disabled: !isSightingEmployee,
      },
      {
        name: documentAction.DISAGREE,
        label: 'Отклонить',
        onClick: this.handleDisagreeButtonClick,
        disabled: !isSightingEmployee,
      },
    ];
  }

  render() {
    const { form, viewName, detailContainer } = this.props;

    return (
      <AdvancedFormDialog
        name={SIGHTING_DOCUMENTS_FORM_DIALOG}
        formName={SIGHTING_DOCUMENTS_FORM}
        dataTableName={SIGHTING_DOCUMENTS_DX_GRID}
        advancedForm={form}
        viewModeTitle="Просмотр документа"
        addModeTitle="Новый документ"
        editModeTitle="Изменение документа"
        customButtons={(viewName === 'resultOfAgreement' ? [] : this.renderCustomButtons())}
        detailContainer={detailContainer}
        entityName={DOCUMENTS}
      />
    );
  }
}

SightingFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
  onRun: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  viewName: PropTypes.string.isRequired,
};

export default SightingFormDialog;
