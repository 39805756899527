/*
 *
 * TenantForm15755
 *
 */

import {TenantForm} from 'pages/Requests/TenantView';
import {
    ON_VISIT,
    ON_CAR_ENTRY,
    ON_PROPERTY_BRINGING,
    ON_PROPERTY_REMOVAL,
    ON_PASS_MAKING,
    SERVICE,
    CARRYING_OUT_WORKS,
} from 'domain/Data/RequestType/constants';
import {ACCEPTED, CREATED, REJECTED} from 'domain/Data/RequestStatus/constants';
import {formField} from 'domain/typeConstants/form';
import * as entity from 'domain/Data/constants';
import {validateCarNumber} from 'utils/validators';
import {
    validateExpirationDate,
    validateGroupRequestVisitorsData,
    validatePassMakingLanguage,
    visitoFullNameHelperText
} from '../utils';
import {GUEST, TEENANT_PARKING_PLACE, TEENANT_PLACES, UNLOADING} from '../common/constants';
import {
    checkpointPostValue,
    departmentCalculatedValue,
    departmentVisibleOptions,
    issueReasonVisibleOptions
} from '../common/fields';
import {groupRequestVisitorsDataHelperText, visitorFullNameLabel} from 'pages/Requests/common/fields';


export class TenantForm15755 extends TenantForm {

    getFields() {
        const currentDate = new Date();

        return [
            {
                name: 'author',
                visible: false,
                initialValue: (values, mode, user) => user.id,
            },
            {
                name: 'arrivalDate',
                type: formField.DATE,
                visible: false,
                calculatedValue: (values, mode) => {
                    if (mode === 'edit' && values.type === ON_VISIT && values.passNumber) {
                        return currentDate
                    }
                    return null
                }
            },
            {
                name: 'departureDate',
                visible: false,
                initialValue: () => null,
            },
            {
                name: 'number',
                label: '№',
                visible: (currentValues, initialValues, mode) => mode === 'edit',
                disabled: true,
            },
            {
                name: 'status',
                type: formField.SELECT,
                label: 'Статус',
                entityName: entity.REQUEST_STATUSES,
                visible: (currentValues, initialValues, mode) => mode === 'view',
                calculatedValue: (values, mode) => {
                    if (mode === 'add' || mode === 'copy') {
                        return [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) ? ACCEPTED : CREATED
                    }
                    return values.status
                },
                orderBy: 'name',
                rowBreakAfter: true,
            },
            {
                name: 'type',
                type: 'select',
                label: 'Вид',
                required: true,
                entityName: 'requestTypes',
                initialValue: (values, mode, user, entities) => {
                    if (mode === 'add' && user.companyId) {
                        const company = entities
                            .get('companies')
                            .get(user.companyId);
                        return company && company.get('requestTypes') ? company.get('requestTypes')[0] : null;
                    }
                    return values.type;
                },
                orderBy: 'order',
                clearErrorsOnChange: true,
                visibleOptions: (currentValues, initialValues, mode, user, entities) => {
                    if (currentValues.company) {
                        const company = entities
                            .get('companies')
                            .get(currentValues.company);
                        return company ? company.get('requestTypes') || [] : [];
                    }
                    return [];
                },
            },
            {
                name: 'longterm',
                type: formField.CHECKBOX,
                label: 'Долгосрочная',
                visible: (values) => ![SERVICE, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, ON_PASS_MAKING].includes(values.type),
            },
            {
                name: 'visitDate',
                label: 'Дата',
                type: formField.DATE,
                required: true,
                initialValue: currentDate,
                min: () => currentDate,
                rowBreakBefore: true,
            },
            {
                name: 'expirationDate',
                label: 'Дата окончания',
                type: formField.DATE,
                required: (values) => values.longterm,
                visible: (values) => values.longterm && values.type !== SERVICE,
                initialValue: (values, mode) => {
                    if (mode === 'copy') {
                        return new Date(values.expirationDate) > currentDate ?
                            new Date(values.expirationDate) : currentDate;
                    }
                    return null;
                },
                calculatedValue: (values) => values.longterm ? values.expirationDate : null,
                min: () => currentDate,
                validate: validateExpirationDate
            },
            {
                name: 'isGroupRequest',
                type: formField.CHECKBOX,
                label: 'Групповая заявка',
                rowBreakBefore: (values) => values.type !== SERVICE,
                visible: (values) => ([ON_CAR_ENTRY, ON_VISIT].includes(values.type)),
            },
            {
                name: 'groupRequestVisitorsData',
                label: (values) => {
                    return values.type === ON_CAR_ENTRY ? 'Список машин' : 'Список посетителей'
                },
                multiline: true,
                helperText: (values) => {
                    return values.type === ON_VISIT ? 'ФИО пишется в одну строку, каждый посетитель пишется с новой строки' :
                        values.type === ON_CAR_ENTRY ? '№ авто (формат: х111хх77) и марка пишутся в одну строку, каждое авто пишется с новой строки' :
                            groupRequestVisitorsDataHelperText()
                },
                visible: (values) => values.isGroupRequest && values.type !== SERVICE,
                validate: validateGroupRequestVisitorsData,
                required: (values) => values.isGroupRequest && values.type !== SERVICE,
                grid: {sm: 12},
                rowBreakBefore: (values) => values.isGroupRequest && values.type !== SERVICE,
            },
            {
                name: 'visitorFullName',
                label: visitorFullNameLabel,
                type: (values) => values.type === ON_PASS_MAKING ? 'text' : 'dadata',
                dadataType: 'NAME',
                dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
                autoFocus: (currentValues, initialValues, mode) => mode === 'add',
                visible: (values) => (!values.isGroupRequest && values.type !== SERVICE) && values.type !== ON_CAR_ENTRY,
                required: (values) => !values.isGroupRequest && [ON_PASS_MAKING, ON_VISIT, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type),
                rowBreakBefore: (values) => !values.isGroupRequest && values.type !== SERVICE,
                validate: validatePassMakingLanguage,
                helperText: visitoFullNameHelperText,
            },
            {
                name: 'companyName',
                label: (values) => 'Подразделение',
                visible: (values) => values.type === ON_PASS_MAKING,
            },
            {
                name: 'company',
                type: formField.AUTOCOMPLETE,
                label: 'Арендатор',
                entityName: entity.COMPANIES,
                orderBy: 'name',
                numberInQueueFromEnd: 1,
                initialValue: (values, mode, user) => user.companyId,
                rowBreakBefore: true,
                disabled: true
            },
            {
                name: 'department',
                type: formField.SELECT,
                entityName: entity.DEPARTMENTS,
                label: 'Здание',
                visible: true,
                required: true,
                initialValue: (values, mode, user) => mode === 'add' ? user.departmentId : values.department,
                calculatedValue: departmentCalculatedValue,
                visibleOptions: departmentVisibleOptions,
            },
            {
                name: 'checkpointPost',
                visible: false,
                initialValue: checkpointPostValue,
                calculatedValue: checkpointPostValue,
            },
            {
                name: 'responsibleFromTenant',
                label: 'Ответственный от арендатора',
                visible: (values) => values.type === SERVICE,
                required: (values) => values.type === SERVICE,
            },
            {
                name: 'passNumber',
                label: '№ пропуска',
                visible: false,
                initialValue: () => null,
            },
            {
                name: 'issueReason',
                type: formField.SELECT,
                label: 'Причина выдачи',
                entityName: entity.ISSUE_REASONS,
                visible: (values) => values.type === ON_PASS_MAKING,
                visibleOptions: issueReasonVisibleOptions,
            },
            {
                name: 'nonstandardCarNumber',
                type: formField.CHECKBOX,
                label: 'Нестандартный № авто',
                rowBreakBefore: false,
                rowBreakAfter: (values) => values.type === ON_CAR_ENTRY,
                visible: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
            },
            {
                name: 'carNumber',
                label: '№ автомобиля',
                required: true,
                visible: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
                normalize: (value) => value ? value.replace(' ', '') : value,
                validate: validateCarNumber,
                rowBreakBefore: true,
            },
            {
                name: 'carModelText',
                label: 'Марка автомобиля',
                visible: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
            },
            {
                name: 'parking',
                type: formField.SELECT,
                label: 'Парковка',
                entityName: entity.PARKINGS,
                visible: (values) => values.type === ON_CAR_ENTRY,
                rowBreakBefore: (values) => values.type === ON_CAR_ENTRY,
                required: (values) => values.type === ON_CAR_ENTRY,
                visibleOptions: (currentValues, initialValues, mode, user, entities) => {
                    if (currentValues.type === ON_CAR_ENTRY) {
                        if (currentValues.company) {
                            const company = entities
                                .get(entity.COMPANIES)
                                .get(currentValues.company);
                            const companyParkings = company ? company.get(entity.PARKINGS) || [] : [];

                            const parkings = entities.get(entity.PARKINGS)
                                .filter((parking) => companyParkings.includes(parking.id))
                                .map((parking) => parking.id);
                            return parkings;
                        }
                    } else if (currentValues.type === ON_PROPERTY_BRINGING || currentValues.type === ON_PROPERTY_REMOVAL) {
                        return [TEENANT_PLACES, GUEST, UNLOADING]
                    }
                }
            },
            {
                name: 'parkingPlace',
                label: '№ места',
                visible: (values) => values.type === ON_CAR_ENTRY && values.parking === TEENANT_PARKING_PLACE,
                initialValue: () => null,
            },
            {
                name: 'materialValuesData',
                label: 'Данные мат. ценностей',
                helperText: 'наименование, кол-во, ед.изм.',
                grid: {sm: 12},
                required: true,
                visible: (values) => values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
            },
            {
                name: 'service',
                type: formField.SELECT,
                label: 'Сервис',
                entityName: entity.SERVICES,
                visible: (values) => values.type === SERVICE,
                rowBreakBefore: true,
            },
            {
                name: 'jobDoing',
                type: formField.SELECT,
                label: 'Работу выполнить',
                entityName: entity.JOB_DOINGS,
                visible: (values) => values.type === SERVICE,
            },
            {
                name: 'whatHappened',
                label: 'Что нужно сделать',
                multiline: false,
                visible: (values) => values.type === SERVICE,
                required: (values) => values.type === SERVICE,
                grid: {sm: 12},
            },
            {
                name: 'assignee',
                type: formField.SELECT,
                label: 'Исполнитель',
                entityName: entity.EMPLOYEES,
                orderBy: 'name',
                cached: false,
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && mode === 'view',
                visibleOptions: (currentValues, initialValue, mode, user, entities) => {
                    const assignee = [];
                    entities.get(entity.EMPLOYEES).map((item) => {
                        if (item.isPerformer) assignee.push(item.id);
                    });
                    return assignee;
                },
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.assignee;
                },
            },
            {
                name: 'executionTerm',
                type: formField.DATE,
                label: 'Срок исполнения',
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && mode === 'view',
                min: () => currentDate,
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.executionTerm;
                },
            },
            {
                name: 'executionDate',
                type: formField.DATE,
                label: 'Дата выполнения факт',
                required: true,
                visible: false,
                min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
                    ? new Date(initialValues.visitDate) : currentDate,
                rowBreakBefore: true,
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.executionDate;
                },
            },
            {
                name: 'whereHappened',
                label: 'Где произошло',
                visible: (values) => values.type === SERVICE,
                required: (values) => values.type === SERVICE,
            },
            {
                name: 'floor',
                label: 'Этаж',
                visible: (values) => values.type === SERVICE,
                required: (values) => values.type === SERVICE,
            },
            {
                name: 'implementationReport',
                label: 'Отчет о выполнении',
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && mode === 'view',
                grid: {sm: 12},
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.implementationReport;
                },
            },
            {
                name: 'managementCompanyComment',
                label: 'Комментарий УК',
                visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
                grid: {sm: 12},
                initialValue: (values, mode) => mode !== 'copy' ? values.managementCompanyComment : null,
                required: (currentValues) => currentValues.status === REJECTED,
            },
            {
                name: 'file',
                label: 'Файл',
                type: formField.FILE,
                visible: (values) => [ON_PASS_MAKING, SERVICE].includes(values.type),
                required: (values) => values.type === ON_PASS_MAKING,
                rowBreakBefore: true,
            },
            {
                name: 'isOffsetCard',
                label: 'Карта на взаимозачет',
                type: formField.CHECKBOX,
                visible: (values) => values.type === ON_PASS_MAKING,
            },
            {
                name: 'note',
                label: 'Примечание',
                grid: {sm: 12},
                visible: (values) => values.type !== SERVICE,
            },
        ];
    }
}



